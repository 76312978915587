import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "d-block" }
const _hoisted_3 = { class: "h4" }
const _hoisted_4 = { class: "btn-toolbar" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "col-12 pb-5" }
const _hoisted_7 = { class: "card card-body border-light shadow-sm" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "fw-bolder" }
const _hoisted_10 = { class: "row justify-content-center text-center py-3" }
const _hoisted_11 = { class: "col-6 col-md-4" }
const _hoisted_12 = { class: "col-6 col-md-4" }
const _hoisted_13 = { class: "col-6 col-md-4" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "row text-center py-3" }
const _hoisted_16 = { class: "col-12 col-md-6" }
const _hoisted_17 = { class: "col-12 col-md-6" }
const _hoisted_18 = { class: "card card-body border-light shadow-sm" }
const _hoisted_19 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, " Gift Card Details for " + _toDisplayString(_ctx.route.query.uid ?? _ctx.route.params.giftCardId), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, " Reload ")
          ])
        ]),
        (_ctx.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                    _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.data.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-gray" }, "Unique ID", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.uid), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "text-gray" }, "Main ID", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.id), 1)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-gray" }, "Image", -1)),
                      _createElementVNode("img", {
                        src: _ctx.data.image,
                        height: "30",
                        width: "60",
                        alt: "Card Image"
                      }, null, 8, _hoisted_14)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-gray" }, "Created At", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.created_at), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-gray" }, "Last Updated", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.updated_at), 1)
                    ])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.UpdateData && _ctx.UpdateData(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_19, [
              _cache[9] || (_cache[9] = _createElementVNode("label", {
                for: "tac",
                class: "form-label"
              }, "Terms and Condition", -1)),
              _withDirectives(_createElementVNode("textarea", {
                class: "form-control",
                id: "tac",
                rows: "6",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tac) = $event)),
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.tac]
              ])
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "mt-3 text-center" }, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-lg btn-outline-primary px-5"
              }, " Save ")
            ], -1))
          ], 32)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}