
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet, apiPut } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";

export default defineComponent({
  name: "AvailableGiftCards",
  components: {
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const tac = ref("");

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/${route.params.giftCardId}`
        );
        data.value = response.data.data.giftCard;
        tac.value = response.data.data.giftCard.tac;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const UpdateData = async () => {
      loading.value = true;
      try {
        await apiPut(`/admin/cards/${route.params.giftCardId}`, {
          tac: tac.value,
        });
        Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      UpdateData,
      route,
      data,
      loading,
      tac,
    };
  },
});
